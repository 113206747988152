.point-status ul {
    padding: 0;
    margin: 0 0 30px;
    border: 1px solid #ddd;
    border-radius: 2px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.point-status ul li {
    padding: 10px 0;
    margin: 0;
    width: 50%;
    list-style: none;
    font-size: 18px;
    font-weight: 500;
}
.point-status ul li:first-child{
    border-right:1px solid #ddd;
}
.fieldIcon {
    float: right;
    margin-left: -25px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

.container{
    padding-top:10px;
    margin: auto;
}

.decoration {
    padding: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: large;
}
.loaderCLass{
    display: block;
    margin: 300px  45%;
    border-color: #FF0000;
    z-index: 1
}
@media (max-width: 767px) {
    .loaderCLass{
        display: block;
        margin: 300px 40%;
        border-color: #FF0000;
        z-index: 1
    }
}

@media (max-width: 420px) {
    .loaderCLass{
        display: block;
        margin: 300px 30%;
        border-color: #FF0000;
        z-index: 1
    }
}

.countryFlagBorder{
    border: 3px solid #c9d6ba
}

.selectedAmountBorder{
    color: #55a00b !important;
    border: 1px solid #17842a !important;
}


.react-datepicker__year-select,
.react-datepicker__month-select{
    display: block;
    width: 105%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


table.dataTable tbody tr.selected {
    background-color: #B0BED9 !important;
}

.description-block{
    display: block;
    margin: 10px 0;
    text-align: center;
}

.description-header{
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
}
.description-header-selected-product{
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
}

.productBlackBox{
    box-sizing: content-box;
    padding: 30px;
    margin-top: 20px;
    border: 2px solid black;
}

.jompayLogo{
    height: 30px;
    width: 30px;
    padding-left: 5px;
}


.fieldIcon {
    float: right;
    margin-left: -25px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}


/*css for role*/
*, *::before, *::after {
    box-sizing: border-box;
}


.role-input {
    position: absolute;
    left: -9999px;
}

.role-label {
    display: block;
    position: relative;
    padding: 10px 6px 15px 6px;
    border-width: 3px 1px 3px 3px;
    border-radius: 100px;
    color: #fff;
    background-color: #5e6d82;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: background-color .2s, box-shadow .2s;
    text-align: center;
}
.role-label-submenu {
    display: block;
    position: relative;
    padding: 10px 6px 15px 6px;
    border-width: 3px 1px 3px 3px;
    border-color: #fff;
    border-style: solid;
    border-radius: 100px;
    color: #fff;
    background-color: #5e6d82;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: background-color .2s, box-shadow .2s;
    text-align: center;
}

.role-label:hover, input:focus + label {
    box-shadow: 0 0 20px rgba(0, 0, 0, .6);
}

input:checked + label {
    background-color: #299a9a;
}

input:checked + label::before {
    background-color: #fff;
}

.noSubMenu {
    font-weight: bold;
    text-align: center;
}

/*css for notification*/


.notification {
    top: 0;
    overflow: hidden;
    width: 100%;
}
.notification h3 {

    font-size: 1em;
    color: #0f460f;
    margin: 0;
    transform: translateX(100%);
    animation: notification 20s linear infinite;
    white-space: nowrap;
}


@media all and (max-width:768px) {
    .notification h3 {
        animation: notification 10s linear infinite;
        line-height: 22px;
    }
    .point-status ul{
        margin: 20px 0;
    }
    .point-status ul li{
        font-size: 13px;
    }
}
/* Move it (define the animation) */
@-moz-keyframes notification {
    0%   { -moz-transform: translateX(100%); }
    100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes notification {
    0%   { -webkit-transform: translateX(100%); }
    100% { -webkit-transform: translateX(-100%); }
}
@keyframes notification {
    0%   {
        -moz-transform: translateX(100%); /* Firefox bug fix */
        -webkit-transform: translateX(100%); /* Firefox bug fix */
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%); /* Firefox bug fix */
        -webkit-transform: translateX(-100%); /* Firefox bug fix */
        transform: translateX(-100%);
    }
}
