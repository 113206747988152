* {
  min-height: 0;
  min-width: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

/* body {
  padding: 70px 0 50px;
  margin: 0;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.15;
  background: #ffffff;
} */

li {
  list-style: none;
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:focus,
a:active {
  outline: 0 !important;
}

button:focus,
button:active {
  outline: 0 !important;
}
.container-receipt {
  max-width: 100%;
  width: 768px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-receipt {
  display: grid;
  grid-template-columns: 1fr 95px;
  grid-gap: 5px;
}
.address-receipt img {
  height: 30px;
  margin-bottom: 15px;
}
.address-receipt h4 {
  font-size: 20px;
  line-height: 1;
  color: #000000;
  font-weight: 500;
  margin: 0 0 10px;
}
.address-receipt p {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  font-weight: 400;
  margin: 0;
}
.sohojlogo img {
  width: 100%;
}

.content-receipt {
  padding: 0;
}
.content-box-receipt {
  border-bottom: 2px dashed #303030;
  padding-bottom: 30px;
}
.content-box-receipt h4 {
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 2px dashed #303030;
  font-weight: 500;
  margin: 20px 0;
}
.content-box-receipt ul {
  padding: 0;
  margin: 0;
}
.content-box-receipt ul li {
  list-style: none;
  font-size: 16px;
  color: #303030;
  font-weight: 400;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 230px 1fr;
}
.content-box-receipt ul li span {
  font-weight: 600;
}
.content-box-receipt ul li:last-child {
  margin-bottom: 0;
}
.footer-receipt {
  text-align: center;
  padding: 20px 0;
}
.footer-receipt h3 {
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  margin: 0;
}
.footer-receipt p {
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  font-weight: 400;
  margin: 15px 0 0;
}
.footer-receipt p a {
  color: #303030;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .header-receipt {
    grid-template-columns: 1fr 65px;
  }
  .address-receipt p {
    font-size: 13px;
  }
  .address-receipt img {
    height: 26px;
  }
  .content-box-receipt ul li {
    font-size: 14px;
    grid-template-columns: 180px 1fr;
  }
}
